import axios from "axios";
import { getBackendUrl, getWbotUrl } from "../config";

const api = axios.create({
	baseURL: getBackendUrl(),
	withCredentials: true,
});

const apiSession = axios.create({
	baseURL: getWbotUrl(),
	withCredentials: true,
});

export  { api , apiSession };
