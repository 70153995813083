import React from "react"
import useS3 from "../../hooks/useS3";

const AudioCors = ({ audioUrl }) => {
  const { blobUrl } = useS3(audioUrl)

  return (
    blobUrl ? (
      <audio controls>
        <source src={blobUrl} type="audio/ogg" />
      </audio>
    ) : null
  );
};

export default AudioCors;
