import { makeStyles } from "@material-ui/core";

const videoStyle = makeStyles((theme) => ({
  messageMedia: {
    maxHeight: "600px",
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "2em",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    borderRadius: "50%",
    width: "64px",
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
  },

  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  closeButton: {
    position: "absolute",
    top: "3vh",
    right: "3vw",
    color: "black",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5em",
    border: "none",
    cursor: "pointer",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  },

  backgroundVideo: {
    position: "relative",
    padding: "150px",
    borderRadius: "5px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default videoStyle;
