import React, { useState, useEffect, useReducer, useRef } from "react";

import { isSameDay, parseISO, format, isToday } from "date-fns";
import clsx from "clsx";

import {
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";

import { api } from "../../services/api";
import toastError from "../../errors/toastError";
import ModalVideoCors from "../ModalVideoCors";
import connectToSocket from "../../services/socket-io";
import PdfCors from "../PdfCors/PdfCors";
import AudioCors from "../AudioCors/AudioCors";
import OtherCors from "../OtherCors/OtherCors";
import messageListStyles from "./css/messageListStyles";

import dotenv from 'dotenv';

dotenv.config();

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({ ticketId, isGroup, setMessagesUpdated }) => {
  const classes = messageListStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);
  const idGraf = localStorage.getItem("idGraf");

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
      
          const { data } = await api.get(`/messages/${ticketId}`, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const socket = connectToSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on(`appMessage-${idGraf}`, (data) => {
      if (data.action === "create") {
        if (ticketId == data.message.ticket.id) {
          dispatch({ type: "ADD_MESSAGE", payload: data.message });
          scrollToBottom();
        }
      }
      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
      setMessagesUpdated(true);
    });

    socket.on("downloadedMedia", (data) => {
      if (data.action === "create") {
        if (ticketId == data.message.ticket.id) {
          dispatch({ type: "ADD_MESSAGE", payload: data.message });
          scrollToBottom();
        }
      }
      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
      setMessagesUpdated(true);
    });


    return () => {
      socket.disconnect();
    };
  }, [setMessagesUpdated, ticketId]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const checkMessageLocation = (message) => {
    if (message.mediaType === "locationMessage" && message.body.split("|").length >= 2) {
      try {
        let locationParts = message.body.split("|");
        let imageLocation = locationParts[0].trim();
        let linkLocation = locationParts[1];

        if (imageLocation !== '' && imageLocation.length > 100) {
          return (
            <LocationPreview
              image={imageLocation}
              link={linkLocation}
            />
          );
        } else {
          return (
            <LocationPreview
              link={linkLocation}
            />
          );
        }
      } catch (err) {
        console.log("checkMessageMedia ~ err", err);
      }
    }
  }

  const checkMessageMedia = (message) => {

    if (message?.mediaType !== "contactMessage") {
      if (message?.mediaUrl && !message?.mediaUrl.endsWith("PENDING") && (!message?.mediaUrl.endsWith("ERROR") || !message?.mediaUrl === "ERROR")) {
        if (message.mediaType === "image") {
          return <ModalImageCors imageUrl={message.mediaUrl} />;
        } else if (message.mediaType === "audio") {
          return (
            <AudioCors audioUrl={message.mediaUrl} />
          );
        } else if (message.mediaType === "video") {
          if (!message.isDeleted) {
            return <ModalVideoCors videoUrl={message.mediaUrl} />;
          }
        } else if (message?.mediaUrl.endsWith(".pdf") || message?.mediaUrl.endsWith(".PDF")) {
          return (
            <PdfCors pdfUrl={message.mediaUrl} />
          )
        } else {
          return (
            <OtherCors otherUrl={message.mediaUrl} />
          );
        }
      } else if (message?.mediaUrl.endsWith("ERROR") || message?.mediaUrl === "ERROR") {
        return (
          <span>
            Erro ao processar mensagem. Solicite o reencaminhamento.
          </span>
        );
      } else {
        return (
          <span className={classes.loadingMessage}>
            <CircularProgress className={classes.circleLoading} />
          </span>
        );
      }
    } else {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }

      return <VcardPreview contact={contact} numbers={obj[0]?.number} />;
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };


  const renderDailyTimestamps = (message, index) => {

    const messageDate = parseISO(message.createdAt);
    const isMessageToday = isToday(messageDate);
    const isNewDay = index === 0 || !isSameDay(messageDate, parseISO(messagesList[index - 1].createdAt));

    if (isNewDay) {
      return (
        <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
          <div className={classes.dailyTimestampText}>
            {isMessageToday ? "Hoje" : format(messageDate, "dd/MM/yyyy")}
          </div>
        </span>
      );
    }

    if (index === messagesList.length - 1) {
      let renderLastMessageRef = (
        <div key={`ref-${message.createdAt}`} ref={lastMessageRef} style={{ float: "left", clear: "both" }} />
      );

      if (!isNewDay) {
        return renderLastMessageRef;
      } else {
        return (
          <>
            <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
              <div className={classes.dailyTimestampText}>
                {isMessageToday ? "Hoje" : format(messageDate, "dd/MM/yyyy")}
              </div>
            </span>
            {renderLastMessageRef}
          </>
        );
      }
    }
  };

  // CODIGO SEM A MELHROIA DA DATA DE HOJE

  // const renderDailyTimestamps = (message, index) => {
  //   if (index === 0 || !isSameDay(parseISO(messagesList[index].createdAt), parseISO(messagesList[index - 1].createdAt))) {
  //     return (
  //       <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
  //         <div className={classes.dailyTimestampText}>
  //           {format(parseISO(message.createdAt), "dd/MM/yyyy")}
  //         </div>
  //       </span>
  //     );
  //   }
  //   if (index === messagesList.length - 1) {
  //     let lastMessageDay = parseISO(message.createdAt);
  //     let preLastMessageDay = parseISO(messagesList[index - 1].createdAt);
  //     let renderLastMessageRef = (
  //       <div key={`ref-${message.createdAt}`} ref={lastMessageRef} style={{ float: "left", clear: "both" }} />
  //     );

  //     if (!isSameDay(lastMessageDay, preLastMessageDay)) {
  //       return (
  //         <>
  //           <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
  //             <div className={classes.dailyTimestampText}>
  //               {format(parseISO(message.createdAt), "dd/MM/yyyy")}
  //             </div>
  //           </span>
  //           {renderLastMessageRef}
  //         </>
  //       );
  //     } else {
  //       return renderLastMessageRef;
  //     }
  //   }
  // };


  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}
          {message.quotedMsg?.body}
        </div>
      </div>
    );
  };


  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (message.mediaType === "call_log") {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageCenter}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                    width="20"
                    height="17"
                  >
                    <path
                      fill="#df3333"
                      d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                    ></path>
                  </svg>{" "}
                  <span>
                    Chamada de voz/vídeo perdida às{" "}
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
        if (!message.fromMe) {

          const messageClass = message?.mediaUrl?.endsWith("ERROR") || message?.mediaUrl === 'ERROR'
            ? classes.messageLeftError
            : classes.messageLeft;

          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div className={messageClass}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}
                {(message.mediaUrl || message.mediaType === "contactMessage") && checkMessageMedia(message)}
                {(message.mediaType === "locationMessage") && checkMessageLocation(message)}

                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}
                >
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.mediaType &&
                    !(
                      message.body === 'audio' ||
                      message.body === 'sticker' ||
                      message.body === 'undefined' ||
                      message?.mediaUrl?.endsWith("ERROR") ||  
                      message?.mediaUrl === "ERROR" ||  
                      (message.mediaType === 'audio' && /\.(mp3|wav|aac)$/i.test(message.body)) ||
                      (message.mediaType === 'video' && /\.(mp4|avi|mkv)$/i.test(message.body)) ||
                      (message.mediaType === 'image' && /\.(jpg|png|jpeg)$/i.test(message.body))
                    ) ? (
                    <MarkdownWrapper isEdited={message.editedMessage}>
                      {message.body || message.dataJson?.message?.ephemeralMessage?.message?.extendedTextMessage?.text}
                    </MarkdownWrapper>
                  ) : (
                    <div style={{ padding: "6px" }}></div>
                  )}

                  <span className={classes.timestamp}>
                    <span className={classes.editedMessageFromClient}>
                      {message?.editedMessage && 'Editada'}
                    </span>
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        } else {

          const messageClass = message?.mediaUrl?.endsWith("ERROR") || message?.mediaUrl === 'ERROR'
            ? classes.messageRightError
            : classes.messageRight;
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div className={messageClass}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {(message.mediaUrl || message.mediaType === "contactMessage") && checkMessageMedia(message)}
                {(message.mediaType === "locationMessage") && checkMessageLocation(message)}
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}
                >
                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {message.mediaType &&
                    !(message.body === 'audio' || message.body === 'sticker' || message.body === 'undefined' ||
                      (message.mediaType === 'audio' && /\.(mp3|wav|aac)$/i.test(message.body)) ||
                      (message.mediaType === 'video' && /\.(mp4|avi|mkv)$/i.test(message.body)) ||
                      (message.mediaType === 'image' && /\.(jpg|png|jpeg)$/i.test(message.body)))
                    ? (
                      <MarkdownWrapper isEdited={message.editedMessage}>
                        {message.body ? message.body : message.dataJson?.message?.ephemeralMessage?.message?.extendedTextMessage?.text}
                      </MarkdownWrapper>
                    ) : (
                      <div style={{ padding: "6px" }}></div>
                    )}

                  {message?.editedMessage && <div className={classes.spacing}></div>}

                  <span className={classes.timestamp}>
                    <span className={classes.editedMessageFromMe}>
                      {message?.editedMessage && 'Editada'}
                    </span>
                    {format(parseISO(message.createdAt), 'HH:mm')}
                    {renderMessageAck(message)}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;
