import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import videoStyle from "./css/videoStyle";
import useS3 from "../../hooks/useS3";

const ModalVideoCors = ({ videoUrl }) => {
  const classes = videoStyle();
  const { blobUrl, thumbnailUrl, loading } = useS3(videoUrl, 'video');
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!videoUrl) return null;

  return (
    <>
      <div
        className={classes.backgroundVideo}
        style={{ backgroundImage: `url(${thumbnailUrl})`, width: '300px' }}
      >
        <button className={classes.playButton} onClick={handleOpen}>
          <span>▶</span>
        </button>
        <Modal open={isOpen} onClose={handleClose} className={classes.modal}>
          <div>
            <button onClick={handleClose} className={classes.closeButton}>
              <span>X</span>
            </button>
            {!loading && (
              <video
                className={classes.messageMedia}
                src={blobUrl}
                controls
                autoPlay
              />
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ModalVideoCors;
