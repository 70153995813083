import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, IconButton, ListItemText, Tooltip } from "@material-ui/core";

const TicketsQueueSelect = ({
  userQueues,
  selectedQueueIds = [],
  onChange,
  isWithoutQueueSelected,
  onIsWithoutQueueSelectedChange,
  open,
  setOpen
}) => {

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const withoutQueueSelected = value.includes('withoutQueue');
    onIsWithoutQueueSelectedChange(withoutQueueSelected);
    const newValue = value.filter(v => v !== 'withoutQueue');
    onChange(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FormControl fullWidth margin="dense">
        <Select
          multiple
          displayEmpty
          disableUnderline
          variant="standard"
          value={isWithoutQueueSelected ? ['withoutQueue', ...selectedQueueIds] : selectedQueueIds}
          onChange={handleChange}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={() => null} // Render nothing for the select input field
          style={{ width: 0, height: 0, overflow: 'hidden', visibility: 'hidden' }}
        >
          <MenuItem dense value="withoutQueue">
            <Checkbox
              checked={isWithoutQueueSelected}
              size="small"
              color={"#000"}
            />
            <ListItemText primary={"Sem Departamento"} />
          </MenuItem>
          {userQueues?.length > 0 &&
            userQueues.map(queue => (
              <MenuItem dense key={queue.id} value={queue.id}>
                <Checkbox
                  style={{
                    color: queue.color,
                  }}
                  size="small"
                  color="primary"
                  checked={selectedQueueIds.indexOf(queue.id) > -1}
                />
                <ListItemText primary={queue.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TicketsQueueSelect;
