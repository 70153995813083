import React, { useEffect } from 'react';
import toastError from "../../errors/toastError";

import { Button, Divider, Typography} from "@material-ui/core";

const LocationPreview = ({ image, link, description }) => {
    useEffect(() => {}, [image, link, description]);

    const handleLocation = async() => {
        try {
            window.open(link);
        } catch (err) {
            toastError(err);
        }
    }

    return (
		<>
			<div style={{
				minWidth: "100px",
			}}>
				<div>
					<div style={{ float: "left" }}>
						{image && <img src={image} alt="location" onClick={handleLocation} style={{ width: "108px" }} />}
						{ description && (
						<div style={{ display: "flex", flexWrap: "wrap" }}>
							<Typography 
								style={{ marginLeft: "9px", fontWeight: "500", fontStyle: "italic" }} 
								variant="subtitle1" 
								gutterBottom
							>
								<div dangerouslySetInnerHTML={{ __html: description.replace('\\n', '<br />') }}></div>
							</Typography>
					    </div>
					
						)}
					</div>
					
					<div style={{ display: "block", content: "", clear: "both" }}></div>
					<div>
						<Divider />
						<Button
							fullWidth
							color="primary"
							onClick={handleLocation}
							disabled={!link}
						>Visualizar</Button>
					</div>
				</div>
			</div>
		</>
	);

};

export default LocationPreview;