import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Ticket from "../../components/Ticket";

import { i18n } from "../../translate/i18n";
import Hidden from "@material-ui/core/Hidden";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    height: "100%",
    overflowY: "hidden",
  },

  chatPapper: {
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxHeight: "100%",
    overflowY: "auto",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const ChatViewCRM = () => {
  const classes = useStyles();
  const { ticketId } = useParams();
  
  return (
    <div className={classes.chatContainer}>
      <Grid container className={classes.chatPapper} spacing={0}>
        <Grid item className={classes.messagessWrapper}>
          {ticketId ? (
            <Ticket />
          ) : (
            <Hidden only={["sm", "xs"]}>
              <Paper className={classes.welcomeMsg}>
                <span>{i18n.t("chat.noTicketMessage")}</span>
              </Paper>
            </Hidden>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatViewCRM;
