import { makeStyles } from "@material-ui/core";

const pdfStyle = makeStyles((theme) => ({
  containerDownloadButton: {
    textAlign: "center",
    margin: "6px 0px 5px 0px",
  },

  iframe: {
    display: "block",
    width: "298px",
    border: "none",
    loading: "lazy",
    pointerEvents: "none",
  },
}));

export default pdfStyle;
