import React  from "react";

function cleanMessageVCard(message) {
    if (!message) return <br />;
    if (message && message.includes("BEGIN:VCARD\nVERSION:3.0\nN:;")) {
        return "Contato";
    } else {
        return message.length > 45 ? `${message.substring(0, 35)}...` : message;
    }
}


export { cleanMessageVCard }