import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { useLocation } from "react-router-dom";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
  const location = useLocation();
  const isChatViewRoute = location.pathname.includes("/ChatViewCrm");
  const { isAuth, loading , isLoginSso } = useContext(AuthContext);
  if (!isAuth && isPrivate && !isLoginSso && !isChatViewRoute) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: `/login/${rest.location || ' '}` , state: { from: rest.location } }} />
      </>
    );
  }

  return (
    <>
      <RouterRoute {...rest} component={Component} />
    </>
  );
};

export default Route;
