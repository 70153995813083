import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const toastError = err => {
    const toastOptions = {
        position: toast.POSITION.BOTTOM_CENTER,
    };
    if (err instanceof DOMException) {
        toast.error(`Error: ${err.message}`, toastOptions);
        return; 
    }
    const errorMsg = err.response?.data?.message || err.response?.data?.error;
    if (errorMsg) {
        if (i18n.exists(`backendErrors.${errorMsg}`)) {
            toast.error(i18n.t(`backendErrors.${errorMsg}`), {
                ...toastOptions,
                toastId: errorMsg,
            });
        } else {
            toast.error(errorMsg, {
                ...toastOptions,
                toastId: errorMsg,
            });
        }
    } else {
        toast.error(`${err}` , toastOptions);
    }
};

export default toastError;
