import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { Badge, Box, Grid, Tooltip } from "@material-ui/core";
import { api } from "../../services/api";
import PubSub from 'pubsub-js';
import "./style.css";
import { cleanMessageVCard } from "../../utils/cleanMessageVCard";
import TicketService from "../../services/Tickets";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import TransferTicketModal from "../TransferTicketModal";

const TicketListItemPending = ({ selectedQueueIds = [] ,selectedTagsIds = [] , visibility }) => {
    const history = useHistory();
    const idGraf = localStorage.getItem("idGraf");
    const { ticketId } = useParams();
    const [tickets, setTickets] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [ticket, setTicket] = useState();
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const pageSize = 15;

    const fetchPendingTickets = async () => {
        setIsLoading(true);
        try {
            const data = await TicketService.getTicketsByStatus(pageSize, pageNumber, "pending", selectedQueueIds , selectedTagsIds , visibility);
            const { tickets: newTickets, hasMore } = data;
            setTickets((prevTickets) => [...prevTickets, ...newTickets]);
            setHasMore(hasMore);
        } catch (error) {
            console.error("Erro ao buscar tickets abertos:", error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (hasMore && !isLoading) {
            fetchPendingTickets();
        }
    }, [pageNumber, hasMore, tickets]);

    useEffect(() => {
        setTickets([]);
        setPageNumber(1);
        setHasMore(true);
    }, [selectedQueueIds , selectedTagsIds]);

    const loadMore = () => {
        if (hasMore && !isLoading) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 1.0 && hasMore && !isLoading) {
            loadMore();
        }
    };


    const handleSelectTicket = async (ticket) => {
        if (ticket.unreadMessages !== 0) {
            try {
                await api.put(`/tickets/${ticket.id}/${idGraf}`, {
                    unreadMessages: 0,
                    isSelected: true,
                });
                PubSub.publish("TICKET_ACTION_SELECTED", { ticketId: ticket.id });
            } catch (error) {
                console.error("Erro ao atualizar o ticket:", error);
            }
        }
        history.push(`/tickets/${ticket.id}`);
    };


    useEffect(() => {
        const handleTicketUpdates = () => {
            const listener = PubSub.subscribe("TICKET_ACTION_UPDATED", async (_, { newStatus, ticketId }) => {
                setTickets((prevTickets) => {
                    if (newStatus === "transferTicket") {
                        return prevTickets.filter((ticket) => ticket.id !== ticketId);
                    }
                    return prevTickets;
                });
            });

            return () => {
                PubSub.unsubscribe(listener);
            };
        };

        const unsubscribe = handleTicketUpdates();
        return () => {
            unsubscribe();
        };
    }, []);



    const handleOpenTransferModal = async (ticket) => {
        setTicket(ticket);
        setTransferTicketModalOpen(true);
    };

    const handleCloseTransferTicketModal = () => {
        setTransferTicketModalOpen(false);
    };


    return (

        <div onScroll={handleScroll} className="box-tickets">
            {tickets.map((ticket) => (
                <React.Fragment key={ticket.id}>
                    <ListItem
                        dense
                        button
                        onClick={() => handleSelectTicket(ticket)}
                        selected={ticketId && +ticketId === ticket.id}
                        className="pendingTicket"
                    >
                        <Tooltip
                            arrow
                            placement="right"
                            title={ticket.queue?.name || "Sem departamento"}
                        >
                            <span
                                style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
                                className="ticketQueueColor"
                            ></span>
                        </Tooltip>
                        <ListItemAvatar>
                            <Avatar src={ticket?.contact?.profilePicUrl} />
                        </ListItemAvatar>
                        <ListItemText
                            disableTypography
                            primary={
                                <Grid
                                    container
                                    spacing={4}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Grid item xs={9} style={{ marginBottom: '-10px' }}>
                                        <Typography
                                            noWrap
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                            style={{ fontWeight: "500" }}
                                        >
                                            {ticket.contact.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="box-lastMessage-lastTime">
                                        {ticket.lastMessage && ticket.lastMessageTime && (
                                            <Typography
                                                className={
                                                    isSameDay(parseISO(ticket.lastMessageTime), new Date())
                                                        ? "lastMessageTime"
                                                        : "lastMessageDate"
                                                }
                                                component="span"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {isSameDay(parseISO(ticket.lastMessageTime), new Date()) ? (
                                                    format(parseISO(ticket.lastMessageTime), "HH:mm")
                                                ) : (
                                                    format(parseISO(ticket.lastMessageTime), "dd/MM/yyyy")
                                                )}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            }
                            secondary={
                                <Grid
                                    container
                                    spacing={5}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Grid item xs={6}>
                                        <Typography
                                            className="contactLastMessage"
                                            noWrap
                                            component="span"
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {cleanMessageVCard(ticket.lastMessage) || <br />}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="box-button-accept">
                                        <Box display="flex" >
                                            <ButtonWithSpinner
                                                color="primary"
                                                variant="contained"
                                                className="reopenButton"
                                                size="small"
                                                loading={false}
                                                onClick={(e) => handleOpenTransferModal(ticket)}
                                                disableElevation
                                            >
                                                {i18n.t("ticketsList.buttons.accept")}
                                            </ButtonWithSpinner>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} className="box-badge">
                                        <Badge
                                            badgeContent={ticket.unreadMessages}
                                            classes={{ badge: "badgeStyle" }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <Divider />
                </React.Fragment>
            ))}
            {transferTicketModalOpen && (
                <TransferTicketModal
                    modalOpen={transferTicketModalOpen}
                    onClose={handleCloseTransferTicketModal}
                    ticketid={ticket.id}
                    ticketWhatsappId={ticket.whatsappId}
                    ticketQueueId={ticket.queueId}
                    ticketStatus={ticket.status}
                />
            )}


        </div>
    );
};

export default TicketListItemPending;
