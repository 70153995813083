import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import openSocket from "../../services/socket-io";
import { api, apiSession } from "../../services/api";
import toastError from "../../errors/toastError";

const useAuth = () => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [user, setUser] = useState({});

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
        setIsAuth(true);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  apiSession.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
        setIsAuth(true);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const { data } = await api.post("/auth/refresh_token");

        if (data) {
          localStorage.setItem("token", JSON.stringify(data.token));
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
        }
        return api(originalRequest);
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        api.defaults.headers.Authorization = undefined;
        setIsAuth(false);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const socket = openSocket();
    socket.on("user", (data) => {
      if (data.action === "update" && data.user.id === user.id) {
        setUser(data.user);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleLogin = async (userData) => {
    try {
      const { data } = await api.post("/auth/login", userData);

      try {
        if (localStorage.getItem("idGraf")) {
          localStorage.removeItem("idGraf");
        }
      } catch (err) {
        console.info(err, "Erro na alteração do idGraf");
      }
      localStorage.setItem("idGraf", data.user.idGraf);
      localStorage.setItem("token", JSON.stringify(data.token));
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setUser(data.user);
      setIsAuth(true);
      await refreshJwtToken();
      history.push("/tickets");
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const handleLoginSso = async ({ tokenErp, ticketId, view, paramEstimate }) => {
    try {
      const tokenObj = { tokenErp };
      const { data } = await api.post("/auth/login/sso", tokenObj);
      localStorage.setItem("token", JSON.stringify(data.token));
      try {
        if (localStorage.getItem("idGraf")) {
          localStorage.removeItem("idGraf");
        }
      } catch (err) {
        console.info(err, "Erro na alteração do idGraf");
      }
      localStorage.setItem("idGraf", data.user.idGraf);
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setUser(data.user);
      setIsAuth(true);
      await refreshJwtToken();
      if (!ticketId && !view) {
        history.push("/tickets");
      } else {
        ticketId = ticketId === "null" ? null : ticketId;

        if (ticketId) {
          history.push(`/ChatViewCrm/${ticketId}/${paramEstimate}`);
        } else {
          history.push(`/ChatViewCrm`);
        }
      }
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const handleLogout = async () => {
    try {
      await api.delete("/auth/logout");
      setIsAuth(false);
      setIsLogout(true);
      setUser({});
      localStorage.removeItem("token");
      api.defaults.headers.Authorization = undefined;
      localStorage.clear();
      history.push("/login");
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const refreshJwtToken = async () => {
    try {
      const { data } = await api.post("/auth/refresh_token");
      localStorage.setItem("token", JSON.stringify(data.token));
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setUser(data.user);
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  return { isAuth, user, isLogout, handleLogin, handleLogout, handleLoginSso };
};

export default useAuth;
