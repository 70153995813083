import React, { useState, useEffect, useRef } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import { i18n } from "../../translate/i18n";

import { api } from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Grid, IconButton, Tooltip, Box } from "@material-ui/core";
import Icon from "../Icon";
import TransferTicketModal from "../TransferTicketModal";
import { setGlobalTabOpen, tabGlobalOpen } from '../../globalState';

import PubSub from 'pubsub-js';

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  closedBadge: {
    marginBottom: 5,
    marginLeft: 7,
  },

  closedLabel: {
    padding: 5,
  },

  contactLastMessage: {
    paddingRight: 20,
  },

  contactLastMessageClosed: {
    paddingRight: 20,
    marginLeft: 25,
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  userTag: {
    position: "absolute",
    marginRight: 5,
    right: 5,
    bottom: 5,
    background: "#2576D2",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },
  gridWithCustomSpacing: {
    width: "calc(100% + 32px) !important",
    margin: "-30px !important",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
    marginRight: "-68px",
  },

  reopenButton: {
    padding: "0px",
    bottom: 7,
    textTransform: "none",
    fontWeight: "500"
  },

  acceptButton: {
    position: "absolute",
    left: "74%",
    padding: "0px",
    bottom: "9px",
    textTransform: "none",
    fontWeight: "500"
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    marginLeft: "47px",
    letterSpacing: "-1px",
  },

  lastMessageDate: {
    marginLeft: "15px",
    letterSpacing: "-1px",
  },
}));

const TicketListItem = ({ ticket, onTicketStatusChange, }) => {
  const classes = useStyles();
  const history = useHistory();
  const { ticketId } = useParams();
  const isMounted = useRef(true);

  const [lastMessage, setLastMessage] = useState("");
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [statusTicket, setStatusTicket] = useState();

  const handleOpenTransferModal = async (e, status) => {
    setStatusTicket(status);
    setTransferTicketModalOpen(true);
  };

  const handleAcepptTicket = async (id) => {
    setStatusTicket('open');
    setTransferTicketModalOpen(true);
  };

  const handleSelectTicket = async (ticket) => {
    if (tabGlobalOpen !== 'closed' && ticket.unreadMessages !== 0) {
      try {
        await api.put(`/tickets/${ticket.id}`, {
          unreadMessages: 0,
          isSelected: true,
        });
        PubSub.publish("TICKET_ACTION_SELECTED", { ticketId: ticket.id });
      } catch (error) {
        console.error("Erro ao atualizar o ticket:", error);
      }
    }
    history.push(`/tickets/${ticket.id}`);
  };

  const handleCloseTransferTicketModal = () => {
    setTransferTicketModalOpen(false);
  };

  const handleSaveTransferTicketModal = () => {
    if (statusTicket !== "closed") {
      handleSelectTicket(ticket)
      onTicketStatusChange(ticket.id);
    }
  };

  function cleanMessage(message) {
    if (!message) return <br />;
    if (message && message.includes("BEGIN:VCARD\nVERSION:3.0\nN:;")) {
      return "Contato";
    } else {
      return message.length > 45 ? `${message.substring(0, 35)}...` : message;
    }
  }

  useEffect(() => {
    setLastMessage(cleanMessage(ticket.lastMessage));
    return () => {
      isMounted.current = false;
    };
  }, [ticket.lastMessage]);


  const dynamicmarginStyle = {
    marginBottom: ticket.status === 'closed' ? 2 : -13,
  };

  return (
    <React.Fragment key={ticket.id}>
      <ListItem
        dense
        button
        onClick={(e) => {
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem departamento"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={9} style={{ marginBottom: -10 }}>
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  style={{ fontWeight: "500" }}
                >
                  {ticket.contact.name}
                </Typography>
              </Grid>
              <Grid item xs={2} style={dynamicmarginStyle}>
                {ticket.lastMessage && ticket.lastMessageTime && (
                  <Typography
                    className={
                      isSameDay(parseISO(ticket.lastMessageTime), new Date())
                        ? classes.lastMessageTime
                        : classes.lastMessageDate
                    }
                    component="span"
                    variant="body2"
                    color="textSecondary"
                  >
                    {isSameDay(parseISO(ticket.lastMessageTime), new Date()) ? (
                      <>{format(parseISO(ticket.lastMessageTime), "HH:mm")}</>
                    ) : (
                      <>{format(parseISO(ticket.lastMessageTime), "dd/MM/yyyy")}</>
                    )}
                  </Typography>
                )}
              </Grid>
            </Grid>
          }
          secondary={
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="flex-start"
              className={
                ticket.status === "closed" ? classes.gridWithCustomSpacing : ""
              }
            >
              <Grid item xs={9}>
                <Typography
                  className={
                    ticket.status === "closed"
                      ? classes.contactLastMessageClosed
                      : classes.contactLastMessage
                  }
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {lastMessage || <br />}
                </Typography>
              </Grid>
              {ticket.status === "closed" && (
                <Grid item xs={2}>
                  <Box display="flex" alignItems="center">
                    <ButtonWithSpinner
                      color="primary"
                      variant="contained"
                      className={classes.reopenButton}
                      size="small"
                      loading={false}
                      onClick={(e) => handleOpenTransferModal(e, "open")}
                      disableElevation
                    >
                      {i18n.t("ticketsList.buttons.accept")}
                    </ButtonWithSpinner>
                    <span className={classes.closedBadge}>
                      <Icon type="solvedTicket" />
                    </span>
                  </Box>
                </Grid>
              )}
              {(ticket.status === "pending" || ticket.status === "open") && (
                <Grid item xs={1}>
                  <Badge
                    className={classes.newMessagesCount}
                    badgeContent={ticket.unreadMessages}
                    classes={{
                      badge: classes.badgeStyle,
                    }}
                  />
                </Grid>
              )}
            </Grid>
          }
        />
        {ticket.status === "pending" && (
          <ButtonWithSpinner
            color="primary"
            variant="contained"
            className={classes.acceptButton}
            size="small"
            loading={false}
            onClick={(e) => handleAcepptTicket(ticket.id)}
          >
            {i18n.t("ticketsList.buttons.accept")}
          </ButtonWithSpinner>
        )}
      </ListItem>
      <Divider variant="inset" component="li" />

      {transferTicketModalOpen && (
        <TransferTicketModal
          modalOpen={transferTicketModalOpen}
          onClose={handleCloseTransferTicketModal}
          onSave={handleSaveTransferTicketModal}
          ticketid={ticket.id}
          ticketWhatsappId={ticket.whatsappId}
          ticketQueueId={ticket.queueId}
          ticketStatus={statusTicket}
        />
      )}

    </React.Fragment>
  );
};

export default TicketListItem;
